import React from "react"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"

import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import ColumnTitle from "components/UI/ColumnTitle"
import { RichTextBody } from "components/UI/RichText"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"

export const query = graphql`
    query($path: String!) {
        mdxContent: mdx(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title
                description
                path
            }
            body
        }
    }
`

const GenericPage = ({
    pageContext: { commonData },
    data: {
        mdxContent: { frontmatter, body },
    },
    location,
}) => {
    const featuredArticles = getFeaturedArticles(commonData)

    return (
        <MainLayout
            header={<Header commonData={commonData} pathname={location.pathname} />}
            sidebar={<FeaturedArticlesSidebar title="FEATURED ARTICLES" featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={frontmatter.title}
                pageDescription={frontmatter.description}
                pagePathname={location.pathname}
            />

            <ColumnTitle>{frontmatter.title}</ColumnTitle>
            <RichTextBody>
                <MDXRenderer>{body}</MDXRenderer>
            </RichTextBody>
        </MainLayout>
    )
}

export default GenericPage
